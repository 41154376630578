/** @jsxImportSource @emotion/react */
import { css, jsx, useTheme } from "@emotion/react";
import { getOpacityColor } from "../../../../utils/color";

export const InsungBottomTextGuide = ({ text }) => {
  const theme = useTheme();
  const opacityColor = getOpacityColor(theme.mainColor);

  return <>{text && <div css={guideWrap({ opacityColor })}>{text}</div>}</>;
};

const guideWrap = ({ opacityColor }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${opacityColor};
  margin-top: 20px;
  height: 80px;
  font-size: 20px;
  white-space: pre-line;
  text-align: center;
`;
