/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { RecButton } from "../system/button/RecButton";

export const LGPilotNotice = ({
  setNoticeModal,
  noticeTitle,
  noticeBody,
  faqEmail,
  faqText,
}) => {
  const nextPage = () => {
    setNoticeModal(false);
  };

  return (
    <div css={totalWrapper}>
      <div
        css={title}
        dangerouslySetInnerHTML={{
          __html: `${noticeTitle}`,
        }}
      ></div>
      <div css={noticeBox}>
        <div
          dangerouslySetInnerHTML={{
            __html: `${noticeBody}`,
          }}
        ></div>
      </div>
      <div css={bottomWrap}>
        <div className="qna-item">
          <span>개선 작업 문의</span>
          <span
            className="contact-email"
            dangerouslySetInnerHTML={{
              __html: `${faqEmail}`,
            }}
          ></span>
        </div>
        <div className="qna-item">
          <span>접속 관련 문의</span>
          <span
            className="contact-email"
            dangerouslySetInnerHTML={{
              __html: `${faqText}`,
            }}
          ></span>
        </div>
      </div>
      <div css={buttonWrap}>
        <RecButton onClick={nextPage}>다음</RecButton>
      </div>
    </div>
  );
};

const title = css`
  font-size: 36px;
  font-weight: 700;
  white-space: pre;
  text-align: center;
  margin: 20px 0 30px 0;
`;

const noticeBox = css`
  background-color: #f2f2f2;
  width: 90%;
  padding: 60px 70px 60px 70px;
  font-size: 19px;
  line-height: 34px;
  max-width: 1040px;
  .bold-text {
    font-weight: bold;
  }

  @media (max-width: 750px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const bottomWrap = css`
  font-size: 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: 25px 0;
  max-width: 1040px;
  .qna-item {
    display: flex;
    gap: 20px;
    font-weight: bold;
  }
  .contact-email {
    color: #9a002e;
  }
`;

const buttonWrap = css`
  min-height: 100px;
  display: flex;
  align-items: center;
`;

const totalWrapper = css`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  p {
    margin-bottom: 0;
  }
`;
