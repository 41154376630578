import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  USER_COMPANY_CODE,
  USER_COMPLETER_REQUEST,
  USER_DATA_REQUEST,
} from "../../_reducers/userAction";
import {
  EXAM_USER_DATA,
  UPDATE_ATTENDANCE,
} from "../../_reducers/examUserDataAction";
import { MAIN_EXAM_REQUEST } from "../../_reducers/mainExamAction";
import {
  TIMING_NOW_BEFORE_EXAM,
  TIMING_NOW_PRACTICE_EXAM,
  TIMING_NOW_MAIN_EXAM,
  TIME_STOP,
} from "../../_reducers/timeAction";
import { Inquire } from "../../components/inquire/Inquire";
import { authApi } from "../../api/auth/authApi";
import { examApi } from "../../api/exam/examApi";
import { dataApi } from "../../api/data/dataApi";
import { LoginTemplate } from "../../components/base/LoginTemplate";
import { getNetworkSpeed } from "../../utils/networkSpeed";
import { persistor } from "../../_store";
import { isFullWidth, toHalfWidth } from "../../utils/validate";
import { LanguageSelect } from "../../components/system/LanguageSelect";
import i18n from "../../locales/i18n";
import { useTranslation } from "react-i18next";
import { SET_THEME } from "../../_reducers/themeAction";
import { Pending404 } from "../../components/pending/Pending404";
import { PendingTemplate } from "../../components/base/PendingTemplate";
import { ModalTemplate } from "../../components/base/ModalTemplate";
import { Loading } from "../../components/modal/Loading";
import { CloseButton } from "../../components/system/button/CloseButton";
import { SET_PRETEST_REQUEST } from "../../_reducers/pretestAction";

export const LoginPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [confirmPhon, setConfirmPhon] = useState(""); //핸드폰 번호
  const [confirmId, setConfirmId] = useState(""); //핸드폰 인증 번호

  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(new URL(url).search);
  const urlCompanyCode = urlParams.get("code");
  const urlTesterCode = window.location.href.split("c=")[1];

  const [isUrlErrorModal, setUrlErrorModal] = useState({
    isModal: false,
    message: "",
  });

  //접속 코드,핸드폰 번호가 전송이 되었는지 안되었는지 확인
  const [isPhoneSended, setPhoneSended] = useState(false);

  //에러, api 요청 데이터 결과 메세지 출력
  const [validationMessage, setValidationMessage] = useState("");

  const [initLayoutCompanyCode, setInitLayoutCompanyCode] = useState("");

  //useRef 변수
  const userIndex = useRef();
  const testerIdx = useRef();
  const isForeignLogin = useRef();
  const isRoomUseYN = useRef();
  const preCompleter = useRef();
  const recordUseYN = useRef();
  const pilotUseYN = useRef();

  //인증번호 중복 호출 방지를 위한 Flag
  let submitFlag = false;

  const [isInitLoading, setInitLoading] = useState(true);
  const [isNotFoundPage, setNotFoundPage] = useState(false);

  const inputReset = () => {
    setPhoneSended(false);
    setConfirmPhon("");
    setConfirmId("");
    setValidationMessage("");
  };

  const getThemeInfo = async (companyCode) => {
    return await dataApi.layoutData(companyCode);
  };
  const getValidateLayoutInfo = async (urlTesterCode) => {
    return await dataApi.validateLayoutData(urlTesterCode);
  };

  useEffect(() => {
    persistor.purge();
    try {
      if (urlCompanyCode) {
        getThemeInfo(urlCompanyCode)
          .then((res) => {
            dispatch({ type: SET_THEME, data: res.data.data });
            setInitLayoutCompanyCode(urlCompanyCode);
            dispatch({
              type: USER_COMPANY_CODE,
              data: urlCompanyCode,
            });
          })
          .catch(() => {
            setNotFoundPage(true);
          })
          .finally(() => {
            setInitLoading(false);
          });
      }
      if (urlTesterCode) {
        getValidateLayoutInfo(urlTesterCode)
          .then((res) => {
            dispatch({ type: SET_THEME, data: res.data.data });
            setInitLayoutCompanyCode(true);
          })
          .catch((error) => {
            if (error.response) {
              const statusCode = error.response.status;
              if (statusCode === 400) {
                setUrlErrorModal({
                  isModal: true,
                  message: error.response.data.message,
                });
              } else if (statusCode === 404) {
                setNotFoundPage(true);
              } else {
                setNotFoundPage(true);
              }
            }
          })
          .finally(() => {
            setInitLoading(false);
          });
      }
      if (!urlCompanyCode && !urlTesterCode) {
        setNotFoundPage(true);
      }
    } catch (e) {
      setInitLoading(false);
      setNotFoundPage(true);
    }
  }, []);

  //핸드폰 번호 입력, 인증번호 입력 change 함수
  const inputChange = useCallback(
    (e) => {
      if (isPhoneSended) {
        setConfirmId(e.target.value);
      } else {
        setConfirmPhon(e.target.value);
      }
      if (validationMessage.trim() !== "") {
        setValidationMessage("");
      }
    },
    [isPhoneSended, validationMessage]
  );

  const sendPhoneNumber = async (companyCode) => {
    await authApi
      .phoneNum(companyCode, i18n.language, {
        testerCell: confirmPhon,
        logNetwork: getNetworkSpeed()?.effectiveType,
      })
      .then((response) => {
        //사전 점검을 완료 여부
        preCompleter.current =
          response.data.data.isPracticeOrExam === "practice" ? false : true;
        dispatch({
          type: USER_COMPLETER_REQUEST,
          data: preCompleter.current,
        });
        //user데이터 정보
        dispatch({
          type: USER_DATA_REQUEST,
          data: response.data.data,
        });
        //응시자 userIndex
        userIndex.current = response.data.data.testerIdx;
        testerIdx.current = response.data.data.testerIdx;
        //해외 응시자 여부
        isForeignLogin.current = response.data.data.isForeignLogin;
        //해외 응시자 일 경우
        if (isForeignLogin.current) {
          setConfirmPhon("");
          setValidationMessage(
            t(`login.validationMessage.isForeignLoginConfirm`)
          );
        }
        //룸 여부 (감독관 여부)
        isRoomUseYN.current = response.data.data.roomUseYN;
        recordUseYN.current = response.data.data.recordUseYN;
        pilotUseYN.current = response.data.data.pilotUseYN;
        setPhoneSended(true);
      })
      .catch((error) => {
        setValidationMessage(error.response.data.message);
        checkSubmitFlagReset();
      });
  };

  const checkPhonAndGetCompanyList = useCallback(async () => {
    setValidationMessage("");

    if (confirmPhon === "") {
      setValidationMessage(t(`login.validationMessage.phoneNumber`));
    } else if (confirmPhon.replace(/-/g, "").length < 10) {
      setValidationMessage(t(`login.validationMessage.phoneNumberLength`));
    } else {
      sendPhoneNumber(urlCompanyCode);
      sessionStorage.setItem("companyCode", urlCompanyCode);
    }
  }, [confirmPhon]);

  /** 인증번호 입력 함수 */
  const sendConfirmId = useCallback(() => {
    let checkConfirmId = confirmId;

    //전각 일 경우
    if (isFullWidth(confirmId)) {
      let result = toHalfWidth(confirmId);
      checkConfirmId = parseInt(result);
    }
    //인증 번호 validation 체크
    if (checkConfirmId === "") {
      if (isForeignLogin.current) {
        return;
      }
      setValidationMessage(t(`login.validationMessage.checkConfirmId`));
    } else if (checkConfirmId.length < 6) {
      setValidationMessage(t(`login.validationMessage.checkConfirmId`));
    } else {
      //인증 번호 send api
      authApi
        .confirmNum(i18n.language, parseInt(testerIdx.current), checkConfirmId)
        .then((response) => {
          //토큰 저장
          const token = response.data.data.accessToken;
          sessionStorage.setItem("accessToken", token);
          //사전 점검 완료자(본검사 대상자) 인인 경우
          if (preCompleter.current) {
            dispatch({ type: TIMING_NOW_BEFORE_EXAM }); //시험 시작 전 타이밍 설정
            //응시자의 상태 데이터 가져오기
            dataApi
              .userData()
              .then((response) => {
                dispatch({
                  type: EXAM_USER_DATA,
                  data: response.data.data,
                });
                let status = response.data.data.status;
                //감독관이 없을 경우
                if (isRoomUseYN.current === "N") {
                  sessionStorage.setItem("remainingSeconds", 0);
                  if (response.data.data.agreeYN === "N") {
                    navigate(`/test/info`, { replace: true });
                    return;
                  }
                  if (response.data.data.agreeYN === "Y") {
                    if (response.data.data.status.examStatus === 0) {
                      if (recordUseYN.current === "N") {
                        if (pilotUseYN.current === "Y") {
                          navigate("/test/timeTable", { replace: true });
                          return;
                        }
                        if (response.data.data.uploadIdFileUrl === null) {
                          navigate(`test/pretest/idcard`, { replace: true });
                          return;
                        }
                        if (status.examStatus === 1) {
                          dispatch({ type: UPDATE_ATTENDANCE, data: "Y" });
                          navigate(`/test/timetable`, { replace: true });
                        } else {
                          navigate(`/test/pretest`, { replace: true });
                          dispatch({
                            type: SET_PRETEST_REQUEST,
                            data: {
                              examNo: 1,
                              examSubNo: 1,
                              pageNo: 1,
                              mode: "MOVE",
                            },
                          });
                        }
                      } else {
                        navigate(`/test/device`, { replace: true });
                      }
                      return;
                    } else if (response.data.data.status.examStatus === 1) {
                      //출석 확인
                      dispatch({ type: TIME_STOP, data: true });
                      examApi
                        .examStart(
                          status.pageNo === undefined ? 1 : status.pageNo,
                          status.examSubStatus,
                          status
                        )
                        .then((result) => {
                          dispatch({
                            type: MAIN_EXAM_REQUEST,
                            data: result.data.data,
                          });
                          //본검사 인지 예제인지 구분하여 남은 시간 세션 스토리지에 저장
                          if (result.data.data.example) {
                            dispatch({ type: TIMING_NOW_PRACTICE_EXAM });
                            sessionStorage.setItem(
                              "remainingSeconds",
                              result.data.data.example.practiceMinutes * 60
                            );
                          } else {
                            dispatch({ type: TIMING_NOW_MAIN_EXAM });
                            sessionStorage.setItem(
                              "remainingSeconds",
                              result.data.data.testerStatus.remainingSeconds
                            );
                          }
                          if (recordUseYN.current === "N") {
                            navigate(`/test/timetable`, { replace: true });
                          } else {
                            navigate(`/test/device`, { replace: true });
                          }
                        });
                      return;
                    }
                  }
                }
                if (isRoomUseYN.current === "Y") {
                  //응시자가 시험 시작전에 들어왔을 경우
                  if (status.examStatus === 0) {
                    let initialTime = parseInt(
                      (new Date(response.data.data.examSAtTime) -
                        new Date(response.data.data.currentTime)) /
                        1000
                    );
                    sessionStorage.setItem("remainingSeconds", initialTime);
                    if (initialTime < 0) {
                      sessionStorage.setItem("remainingSeconds", 0);
                      dispatch({ type: TIME_STOP, data: true });
                    }
                    //본 검사에서 개인정보 동의 서약이 Y일경우 보여주지 않고 , N일 경우 보여주기
                    if (response.data.data.agreeYN === "N") {
                      navigate(`/test/info`, { replace: true });
                    } else {
                      //개인 정보 동의와 신분 확인이 된 경우
                      navigate(`/test/device`, { replace: true });
                    }
                  }
                  //응시자가 시험 중간에 튕겼다가 재 접속 했을 경우
                  if (status.examStatus === 1) {
                    if (response.data.data.agreeYN === "N") {
                      navigate(`/test/info`, { replace: true });
                      return;
                    }

                    //신분증 완료가 안 됐을 경우
                    if (response.data.data.attendanceYN === "N") {
                      navigate(`/test/device`, { replace: true });
                    }
                    //신분증 완료자인 경우
                    else {
                      //타이머 멈춤
                      dispatch({ type: TIME_STOP, data: true });
                      //문항 가져오기
                      examApi
                        .examStart(
                          status.pageNo === undefined ? 1 : status.pageNo,
                          status.examSubStatus,
                          status
                        )
                        .then((result) => {
                          dispatch({
                            type: MAIN_EXAM_REQUEST,
                            data: result.data.data,
                          });
                          //본검사 인지 예제인지 구분하여 남은 시간 세션 스토리지에 저장
                          if (result.data.data.example) {
                            dispatch({ type: TIMING_NOW_PRACTICE_EXAM });
                            sessionStorage.setItem(
                              "remainingSeconds",
                              result.data.data.example.practiceMinutes * 60
                            );
                          } else {
                            dispatch({ type: TIMING_NOW_MAIN_EXAM });
                            sessionStorage.setItem(
                              "remainingSeconds",
                              result.data.data.testerStatus.remainingSeconds
                            );
                          }
                          navigate("/test/device", { replace: true });
                        })
                        .catch((error) => {
                          setPhoneSended(false);
                          setValidationMessage(error.response.data.message);
                        });
                    }
                  }
                  //이미 시험 응시가 완료 된 사람은 로그아웃으로
                  if (response.data.data.status.examStatus === 2) {
                    navigate(`/logout`, { replace: true });
                  }
                }
              })
              .catch((error) => {
                setValidationMessage(error.response.data.message);
                checkSubmitFlagReset();
              });
          } else {
            //사전 점검 대상자 인 경우
            navigate(`/test/info`, { replace: true });
          }
        })
        .catch((error) => {
          setValidationMessage(error.response.data.message);
          checkSubmitFlagReset();
        });
    }
  }, [confirmId]);

  const checkSubmitFlagReset = () => {
    submitFlag = false;
  };

  /** 중복호출 방지를 위한 함수 */
  const checkSubmitFlag = () => {
    if (submitFlag) {
      return submitFlag;
    } else {
      submitFlag = true;
      return false;
    }
  };

  //로그인 버튼 누를 시
  //핸드폰 번호 인증이 안된 상태면 sendPhoneNumber 함수 실행
  //핸드폰 인증 완료 시 입력 코드 sendConfirmId 함수 실행
  const onSubmitForm = useCallback(
    (event) => {
      event.preventDefault();
      if (checkSubmitFlag()) {
        return;
      }
      if (isPhoneSended) {
        sendConfirmId();
      } else {
        checkPhonAndGetCompanyList();
      }
    },
    [sendConfirmId, checkPhonAndGetCompanyList, isPhoneSended, confirmPhon]
  );

  return (
    <>
      {isUrlErrorModal.isModal && (
        <ModalTemplate isTest={false}>{isUrlErrorModal.message}</ModalTemplate>
      )}
      {isInitLoading && (
        <PendingTemplate>
          <Loading />
        </PendingTemplate>
      )}
      {initLayoutCompanyCode && (
        <>
          <CloseButton />
          <LanguageSelect />
          <LoginTemplate
            companyCode={initLayoutCompanyCode}
            confirmPhon={confirmPhon}
            confirmId={confirmId}
            isPhoneSended={isPhoneSended}
            validationMessage={validationMessage}
            inputChange={inputChange}
            onSubmitForm={onSubmitForm}
            inputReset={inputReset}
          />
          <Inquire />
        </>
      )}
      {isNotFoundPage && <Pending404 />}
    </>
  );
};
