/* @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_AGREE } from "../../_reducers/examUserDataAction";
import { ALERT_MODAL_ON } from "../../_reducers/modalAction";
import { dataApi } from "../../api/data/dataApi";
import { updateApi } from "../../api/update/updateApi";
import { RecButton } from "../../components/system/button/RecButton";
import { Inquire } from "../../components/inquire/Inquire";
import { CheckButton } from "../../components/system/button/CheckButton";
import { ModalTemplate } from "../../components/base/ModalTemplate";
import { BasicButton } from "../../components/system/button/BasicButton";
import { useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";
import { LanguageSelect } from "../../components/system/LanguageSelect";
import { SET_PRETEST_REQUEST } from "../../_reducers/pretestAction";
import { LGPilotNotice } from "../../components/notice/LGPlilotNotice";
import { Loading } from "../../components/modal/Loading";

export const AgreementPage = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { titleText } = useSelector((state) => state.themeAction.theme);
  const prevScrollY = useRef(0);
  const divRef = useRef([]);

  const [infoData, setInfoData] = useState([]);
  const [loginPopUp, setLoginPopUp] = useState([]);

  const [checkedList, setCheckedList] = useState([]);
  const [scroll, setScroll] = useState(); //스크롤이 내려 갔을 때 감지 state
  const [select, setSelect] = useState(0);
  const [nowDivBoxSize, setNowDivBoxSize] = useState("");
  const [modal, setModal] = useState(true);
  const { recordUseYN, pilotUseYN } = useSelector(
    (state) => state.userAction.data
  );
  const { uploadIdFileUrl } = useSelector((state) => state.examUserDataAction);

  const [isNoticeModal, setNoticeModal] = useState(
    pilotUseYN === "Y" ? true : false
  );

  // 로딩
  const [isInfoDataLoading, setInfoDataLoading] = useState(true);
  const [isNextLoading, setNextLoading] = useState(false);

  //응시자의 화면 사이즈에 따라서 Div height 조절
  useEffect(() => {
    if (window.screen.width >= 1599) {
      setNowDivBoxSize("400px");
    } else {
      setNowDivBoxSize("300px");
    }
  }, [nowDivBoxSize]);

  //개인 정보 데이터 가져오기
  useEffect(() => {
    let isCancelled = false;
    setInfoDataLoading(true);
    async function fetchInfoData() {
      const result = await dataApi.agreementData(i18n.language);
      if (!isCancelled) {
        setInfoDataLoading(false);
        setInfoData(result.data.data.terms);
        setLoginPopUp(result.data.data.loginPopUp);
      }
    }

    fetchInfoData();
    return () => {
      isCancelled = true;
    };
  }, [i18n.language]);

  //초기 값 설정
  useEffect(() => {
    let checkedList = [];
    let scroll = [];
    infoData?.map((item, i) => {
      checkedList[i] = false;
      scroll[i] = false;
    });

    setScroll(scroll);
    setCheckedList(checkedList);
  }, [infoData]);

  const toggle = (i) => {
    setSelect(i);
  };

  const onClickChecked = (e, i) => {
    const scrollHeight = divRef.current[i].scrollHeight;
    const tmp = [...checkedList];

    // 스크롤 성공인 상태에서
    if (scrollHeight + "px" === nowDivBoxSize) {
      tmp[i] = true;
      toggle(i);
      setScroll(tmp);
      //체크가 안 돼 있으면
      if (checkedList[i] === false) {
        setCheckedList(tmp);
      }
    }

    //이미 스크롤이 성공이면
    if (scroll[i]) {
      tmp[i] = true;
      toggle(i);
      setCheckedList(tmp);
    }

    //스크롤 체크 둘다 체크 될 경우
    if (scroll[i] && checkedList[i]) {
      tmp[i] = false;
      setCheckedList(tmp);
    }
  };

  const onClickNext = () => {
    if (checkedList?.findIndex((e) => e === false) === -1 && !undefined) {
      setNextLoading(true);
      updateApi
        .updateAgreement()
        .then((response) => {
          dispatch({ type: UPDATE_AGREE, data: "Y" });
          if (recordUseYN === "N") {
            if (pilotUseYN === "Y") {
              navigate("/test/timeTable", { replace: true });
            } else if (!uploadIdFileUrl) {
              navigate("/test/pretest/idcard", { replace: true });
            } else {
              navigate(`/test/pretest`, { replace: true });
              dispatch({
                type: SET_PRETEST_REQUEST,
                data: {
                  examNo: 1,
                  examSubNo: 1,
                  pageNo: 1,
                  mode: "MOVE",
                },
              });
            }
          } else {
            navigate(`/test/device`, { replace: true });
          }
          setNextLoading(false);
        })
        .catch((error) => {
          dispatch({
            type: ALERT_MODAL_ON,
            data: error.response.data.message,
          });
          setNextLoading(false);
        });
    } else {
      dispatch({
        type: ALERT_MODAL_ON,
        data: t(`agreement.modal.agreeAll`),
      });
    }
  };

  const onChangeEach = (e, index) => {
    const scrollHeight = divRef.current[index].scrollHeight;

    //스크롤이 끝까지 가지 않았을 경우
    if (scroll[index] !== true && scrollHeight + "px" !== nowDivBoxSize) {
      dispatch({
        type: ALERT_MODAL_ON,
        data: t(`agreement.modal.agreeScroll`),
      });
    }
    //체크가 됐을 경우
    if (
      (scroll[index] && e.target.checked) ||
      (scrollHeight + "px" === nowDivBoxSize && e.target.checked)
    ) {
      if (scroll.length === index + 1) {
        setSelect(index);
      } else {
        setSelect(index + 1);
      }
    }
  };

  const onScroll = (e, num) => {
    const currentScrollY = e.target.scrollTop; //현재 값
    const maxScrollLeft = e.target.scrollHeight - e.target.clientHeight; //최대 수직 스크롤 값

    if (maxScrollLeft - 10 <= currentScrollY) {
      const copyArray = [...scroll];
      copyArray[num] = true;
      setScroll(copyArray);
    }
    prevScrollY.current = currentScrollY - 10;
  };

  return (
    <>
      <LanguageSelect />

      {!isInfoDataLoading ? (
        isNoticeModal ? (
          <LGPilotNotice
            setNoticeModal={setNoticeModal}
            noticeTitle={loginPopUp.loginPopUpTitle}
            noticeBody={loginPopUp.loginPopUpBody}
            faqEmail={loginPopUp.loginPopUpEmail}
            faqText={loginPopUp.loginPopUpQNA}
          />
        ) : (
          <div css={totalWrapper}>
            {modal ? (
              <ModalTemplate
                closeBtn={true}
                setModal={setModal}
                title={titleText}
              >
                <div
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    paddingBottom: "20px",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {pilotUseYN === "Y"
                    ? t(`agreement.modal.pilotInitText`)
                    : t(`agreement.modal.initText`)}
                </div>
                <BasicButton
                  wid="s"
                  hei="s"
                  onClick={() => setModal(false)}
                  label={t(`agreement.modal.checkLabel`)}
                />
              </ModalTemplate>
            ) : null}
            <div css={InfoContainer({ nowDivBoxSize })}>
              {infoData?.length > 0 &&
                infoData?.map((data, i) => {
                  return (
                    <div className="contents" key={i}>
                      <div className="info-wrapper">
                        <div className="info-title" onClick={() => toggle(i)}>
                          {data.title}
                          <div className="check-wrap">
                            <CheckButton
                              checked={checkedList[i] || ""}
                              onChange={(e) => onChangeEach(e, i)}
                              value={i}
                              onClick={(e) => onClickChecked(e, i)}
                              label={t(`agreement.modal.agreeLabel`)}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={select === i ? "faqWrap-show" : "faqWrap"}
                      >
                        <div
                          ref={(e) => (divRef.current[i] = e)}
                          onScroll={(e) => onScroll(e, i)}
                          className={"faq-content-show"}
                          dangerouslySetInnerHTML={{
                            __html: `${data.text}`,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {isNextLoading ? (
              <RecButton label={t(`agreement.loadingButtonLabel`)} />
            ) : (
              <RecButton
                disabled={
                  checkedList?.findIndex((e) => e === false) === -1 &&
                  !undefined
                    ? false
                    : true
                }
                onClick={onClickNext}
                label={t(`agreement.buttonLabel`)}
              />
            )}
          </div>
        )
      ) : (
        <div css={loadingdWrap}>
          <Loading />
        </div>
      )}
      <Inquire />
    </>
  );
};

const loadingdWrap = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const totalWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`;

const InfoContainer = ({ nowDivBoxSize }) => css`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f1;
  width: 80vw;
  padding: 16px 50px;
  border-radius: 6px;
  overflow: auto;

  .info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .info-title {
    display: flex;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .check-wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .checkbox-container {
    font-size: 20px;
    height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 40px;
    cursor: pointer;
    position: relative;
    user-select: none;
  }
  .faqWrap {
    overflow: hidden;
    height: 0;
  }

  .faqWrap-show {
    overflow: auto;
    height: auto;
  }
  .faq-content-show {
    display: flex;
    height: ${nowDivBoxSize};
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 18px 40px;
    background-color: #fff;
    p {
      margin: 0;
    }
  }
`;
