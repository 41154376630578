/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BasicButton } from "../../components/system/button/BasicButton";
import { useLogout } from "../../hooks/useLogout";
import { UPDATE_USER_STATE } from "../../_reducers/examUserDataAction";
import { useRecord } from "../../hooks/useRecord";
import { useTranslation } from "react-i18next";
import { MAIN_EXAM_REQUEST } from "../../_reducers/mainExamAction";
import { examApi } from "../../api/exam/examApi";
import { EndSteps } from "../system/EndSteps";
import { useRoomConnection } from "../../adapters/useRoomConnection";
import { Loading } from "../modal/Loading";

export const PendingExamEnd = () => {
  const { t } = useTranslation();
  const { sendToExamTopic } = useRoomConnection();
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const {
    stopRecordHandler,
    loading: recordingLoading,
    setLoading,
  } = useRecord();
  const examData = useSelector((state) => state.mainExamAction.data);
  const [isExamEndModal, setExamEndModal] = useState(false);
  const [isRecordingCompleteModal, setRecordingCompleteModal] = useState(false);
  const socketData = useSelector((state) => state.socketAction);
  const [activeStep, setActiveStep] = useState(1);
  const { recordUseYN } = useSelector((state) => state.userAction.data);

  //캠, 스크린, 녹화영상을 모두 사용하지 않는 경우
  const isMediaOff = recordUseYN === "N";

  const initialSteps = isMediaOff
    ? [
        { label: t(`pending.examEnd.steps.1`), step: 1, status: "waiting" },
        { label: t(`pending.examEnd.steps.3`), step: 2, status: "waiting" },
      ]
    : [
        { label: t(`pending.examEnd.steps.1`), step: 1, status: "waiting" },
        { label: t(`pending.examEnd.steps.2`), step: 2, status: "waiting" },
        { label: t(`pending.examEnd.steps.3`), step: 3, status: "waiting" },
      ];

  const [steps, setSteps] = useState(initialSteps);

  const updateStepsStatus = (index, status) => {
    setLoading(true);
    let tmpArray = [...steps];
    tmpArray[index - 1].status = status;
    setSteps(tmpArray);
  };

  const sendExamEndApi = () => {
    setExamEndModal(true);

    examApi
      .examStart(examData?.testerStatus?.savePage, 2, examData?.testerStatus)
      .then((response) => {
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: response.data.data,
        });
        //검사 종료 시 응시자 상태 업데이트
        dispatch({
          type: UPDATE_USER_STATE,
          data: 2,
        });
      })
      .catch(() => {
        dispatch({
          type: UPDATE_USER_STATE,
          data: 2,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setExamEndModal(false);
          setActiveStep((prev) => prev + 1);
          updateStepsStatus(1, "complete");
          if (isMediaOff) {
            handleRecordingComplete("complete");
          } else {
            sendRecordApi();
          }
        }, 3000);
      });
  };

  const sendRecordApi = async () => {
    setRecordingCompleteModal(true);
    stopRecordHandler(true);
  };

  const handleRecordingComplete = (status) => {
    setTimeout(() => {
      setRecordingCompleteModal(false);
      setActiveStep(steps.length);
      updateStepsStatus(2, status);
    }, 3000);
  };

  useEffect(() => {
    if (!isExamEndModal && isRecordingCompleteModal) {
      if (!recordingLoading || recordingLoading === "error") {
        handleRecordingComplete("complete");
      }
    }
  }, [
    isExamEndModal,
    isRecordingCompleteModal,
    recordingLoading,
    handleRecordingComplete,
    steps,
  ]);

  useEffect(() => {
    sendExamEndApi();
  }, []);

  const renderEndPending = () =>
    isExamEndModal || isRecordingCompleteModal
      ? `${t(`pending.examEnd.title`)}\n${t(`pending.examEnd.waitingText`)}`
      : t(`pending.examEnd.endText`);

  return (
    <div css={totalWrapper}>
      <div className="text">{renderEndPending()}</div>
      <EndSteps activeStep={activeStep} steps={steps} />
      <div className="loading-wrap">
        {!isExamEndModal && !isRecordingCompleteModal ? (
          <BasicButton
            wid="s"
            hei="s"
            onClick={() => {
              sendToExamTopic(
                socketData?.viewerSocket?.viewerSocketId,
                "COMPLETE"
              );
              logout();
            }}
            label={t(`exam.button.end`)}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

const totalWrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.4;
  user-select: none;
  z-index: 999;
  position: absolute;
  background-color: #fff;
  white-space: pre-line;
  text-align: center;
  .loading-wrap {
    height: 80px;
  }
  .text {
    padding-top: 80px;
    font-weight: bold;
    font-size: 32px;
  }
  .loading-wrapper {
    padding-top: 60px;
  }
`;
