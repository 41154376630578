/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./Calc.module.css";
import { useSelector } from "react-redux";
import { useNowExamData } from "../../../hooks/useNowExamData";

const CalcDisplay = forwardRef((props, ref) => {
  //수식 디스플레이
  const [calcDisplay, setCalcDisplay] = useState("");
  //결과값 디스플레이
  const [resultDisplay, setResultDisplay] = useState([]);
  //응시자에게 보여지는 디스플레이
  const [testDisplay, setTestDisplay] = useState("");

  //마지막에 계산하기버튼(=)을 눌렀는지 안 눌렀는지
  // const [isResult, setIsResult] = useState(false);
  const isResult = useRef(false);

  //닫히지 않은 괄호 개수
  //useRef를 사용하면 재렌더링되더라도 값이 초기화되지 않음
  const bracketCount = useRef(0);
  const calcOperator = useRef("");

  //루트 카운트
  const rootCount = useRef(0);

  //.카운트
  const [dotCount, setDotCount] = useState(true);

  const [isFontSmall, setFontSmall] = useState(false);

  useEffect(() => {
    //특정 길이 이상이면 폰트 사이즈 작아지게
    if (calcOperator.current.length > 28 && calcOperator.current.length <= 38) {
      // setCalcDisplay("E");
      // setTestDisplay("E");
      // setResultDisplay(["E", ...resultDisplay]);
      setFontSmall(1);
    } else if (
      0 <= calcOperator.current.length &&
      calcOperator.current.length <= 28
    ) {
      setFontSmall(false);
    } else if (calcOperator.current.length >= 39) {
      setFontSmall(2);
      // setResultDisplay([resultDisplay[0]?.replace("E", ""), ...resultDisplay]);
    }
  }, [calcDisplay]);

  //부모 컴포넌트에서 자식 컴포넌트의 함수 사용할 수 있도록 연결
  useImperativeHandle(ref.calcDisplayRef, () => ({
    // 부모 컴포넌트에서 사용할 함수 목록 콤마로 구분
    clearCalc,
    removeOne,
    calcAddOper,
    equalClick,
    rootClick,
  }));

  //숫자, 연산자 입력함수
  const calcAddOper = useCallback(
    (val) => {
      if (calcOperator.current.length >= 39) return;
      if (isResult.current) {
        if (val === "÷" || val === "x" || val === "+" || val === "-") {
          isResult.current = false;
        } else {
          //숫자가 올 경우
          setResultDisplay([`Ans = ${calcOperator.current}`]);
          if (val === "00") {
            calcOperator.current = "0";
          } else {
            calcOperator.current = "" + val;
          }

          setCalcDisplay(calcOperator.current);
          setTestDisplay(
            calcOperator.current.replace(")", "").replace("(", "")
          );
          isResult.current = false;
          return;
        }
      }

      //연산자 일 경우
      switch (val) {
        case "÷":
        case "x":
        case "*":
        case "-":
        case "+":
          if (val !== ".") {
            rootCount.current = 0; //연산자 입력 시 루트 카운트 초기화
          }
          let iLen = calcOperator.current.length;
          if (iLen) {
            let lastOper = calcOperator.current.substring(iLen, iLen - 1);
            //연산자가 연속으로 두번 들어왔을 때 마지막 연산자로 교체
            if (val === "-" && (lastOper === "x" || lastOper === "÷")) {
              calcOperator.current = calcOperator.current + val;
              //화면상에 보이는 *를 X로 바꿔준다
              calcOperator.current = calcOperator.current.replace(/\*/g, "x");
              setCalcDisplay(calcOperator.current);
              setTestDisplay(
                calcOperator.current.replace(")", "").replace("(", "")
              );

              return;
            }
            switch (lastOper) {
              case "÷":
              case "x":
              case "*":
              case "-":
              case "+":
              case ".":
                calcOperator.current = calcOperator.current.substring(
                  0,
                  iLen - 1
                );
                break;
              case "(":
                calcOperator.current = calcOperator.current.substring(
                  0,
                  iLen - 2
                );
                break;
              default:
            }
          }
          break;
        default:
          break;
      }

      //"." 계산
      if (val === ".") {
        setDotCount(false);
      }
      switch (val) {
        case "÷":
        case "x":
        case "*":
        case "-":
        case "+":
          setDotCount(true);
          break;
        default:
          break;
      }

      if (!dotCount && val === ".") {
        return;
      }

      //숫자가 입력 된 경우
      calcOperator.current = calcOperator.current + val;
      let iLen = calcOperator.current.length;
      let lastOper = calcOperator.current.substring(iLen - 1, iLen - 2);

      //    루트 맨 앞에 0, 00 막기
      // if (
      //   calcOperator.current.substring(iLen, iLen - 3) === "(00" &&
      //   val === "00"
      // ) {
      //   calcOperator.current = calcOperator.current.substring(0, iLen - 2);
      //   return;
      // }

      // if (lastOper === "(" && val === "0") {
      //   calcOperator.current = calcOperator.current.substring(0, iLen - 1);
      //   return;
      // }

      //0일 경우
      if (calcOperator.current === "00" || calcOperator.current === "000") {
        calcOperator.current = "0";
      } else if (calcOperator.current === ".") {
        calcOperator.current = "0.";
      }

      //루트나 연산자앞에 00이 오면 자동으로 0으로 바꿔주기

      //루트로 인한 괄호
      if (rootCount.current === 1) {
        //앞에 괄호가 있으면 앞에 있던 괄호 제거
        if (lastOper === ")" || lastOper === "0") {
          if (val === "00") {
            calcOperator.current = calcOperator.current.substring(0, iLen - 3);
            calcOperator.current = calcOperator.current + val;
            calcOperator.current = calcOperator.current.concat(")");
          } else {
            calcOperator.current = calcOperator.current.substring(0, iLen - 2);
            calcOperator.current = calcOperator.current + val;
            calcOperator.current = calcOperator.current.concat(")");
          }
        } else {
          //앞에 루트가 있으면서 괄호가 없으면 괄호를 추가
          calcOperator.current = calcOperator.current.concat(")");
        }
      }

      //화면상에 보이는 *를 X로 바꿔준다
      calcOperator.current = calcOperator.current.replace(/\*/g, "x");
      setCalcDisplay(calcOperator.current);
      setTestDisplay(calcOperator.current.replace(")", "").replace("(", ""));

      //처음에 연산자가 올 경우 막기
      if (
        calcOperator.current === "÷" ||
        calcOperator.current === "x" ||
        calcOperator.current === "*" ||
        calcOperator.current === "√(" ||
        calcOperator.current === "E" ||
        calcOperator.current === "0"
      ) {
        calcOperator.current = "";
      }
    },
    [calcDisplay]
  );

  //한 글자 지우기(백스페이스)
  const removeOne = useCallback(() => {
    let iLen = calcOperator.current.length;
    const removeNum = String(calcOperator.current).charAt(iLen - 1);
    let removeLen;
    switch (removeNum) {
      case "÷":
      case "x":
      case "*":
      case "-":
      case "+":
        setDotCount(true);
        break;
      default:
        break;
    }

    if (iLen > 1) {
      //마지막이 √( 일 경우, )일 경우 2자리 삭제
      if (
        calcOperator.current.substring(iLen, iLen - 2) === "\u221a(" ||
        removeNum === ")"
      ) {
        removeLen = 2;
      } else {
        removeLen = 1;
      }
      calcOperator.current = calcOperator.current.substring(
        0,
        iLen - removeLen
      );

      if (removeNum === ")") {
        calcOperator.current = calcOperator.current.concat(")");
      }
      if (calcOperator.current.substring(iLen, iLen - 4) === "√()") {
        calcOperator.current = calcOperator.current.substring(0, iLen - 4);
        rootCount.current = 0;
      }

      // calcEvaluate();
      setCalcDisplay(calcOperator.current);
      setTestDisplay(calcOperator.current);
    } else if (iLen === 1) {
      //초기화
      calcOperator.current = "";
      setCalcDisplay("");
      setTestDisplay("");
      rootCount.current = 0;
      bracketCount.current = 0;
      isResult.current = false;
    }
  }, []);

  const clearCalc = useCallback(() => {
    setDotCount(true);
    calcOperator.current = "";
    setCalcDisplay("");
    setResultDisplay([]);
    setTestDisplay("");
    rootCount.current = 0;
    bracketCount.current = 0;
    isResult.current = false;
  }, []);

  const rootClick = useCallback(() => {
    isResult.current = false;
    //마지막에 =을 눌렀을 경우 *루트 해주기
    if (isResult.current) {
      calcOperator.current = calcOperator.current + "x\u221a(";
      return;
    }
    //루트 카운트 증가
    rootCount.current = 1;
    //마지막 값이 ( 일 경욷 루트 제거 (루트 중복 누르기 방지)
    let iLen = calcOperator.current.length;
    let lastOper = String(calcOperator.current).substring(iLen, iLen - 1);
    if (lastOper === "(") {
      calcOperator.current = String(calcOperator.current).substring(
        0,
        iLen - 2
      );
    }

    //루트 추가
    //만약 루트 앞에 )가 있으면 곱하기 연산 적용
    if (lastOper === ")") {
      calcOperator.current = calcOperator.current + "x\u221a(";
    } else {
      calcOperator.current = calcOperator.current + "\u221a(";
    }

    setCalcDisplay(calcOperator.current);
    setTestDisplay(calcOperator.current.replace(")", "").replace("(", ""));
  }, []);

  useEffect(() => {
    let tmp = String(testDisplay).replace(")", "").replace("(", "");
    setTestDisplay(tmp);
  }, [testDisplay]);

  const formatResult = (result) => {
    if (
      result === "E" ||
      result === Infinity ||
      result === -Infinity ||
      isNaN(result)
    ) {
      return "E";
    }

    // 소수점 자릿수 제한을 설정 (15자리)
    const decimalPlaces = 13;
    const multiplier = Math.pow(10, decimalPlaces);

    const preciseResult = Math.round(result * multiplier) / multiplier;

    // 부동소수점 오차 보정 후 정수 또는 소수점 출력
    const correctedResult = Math.round(preciseResult * 1e12) / 1e12;

    return Number.isInteger(correctedResult)
      ? correctedResult.toString()
      : correctedResult.toFixed(decimalPlaces).replace(/\.?0+$/, "");
  };

  const equalClick = () => {
    setFontSmall(false);

    isResult.current = true;
    rootCount.current = 0;
    //계산을 위해 x를 *로 바꿔준다
    const operation = replaceOperator(calcOperator.current);
    //부동 소수점 이슈 해결 방안
    const calcResult = newEval(operation);

    const formattedResult = formatResult(calcResult);

    if (operation === "") return;
    if (formattedResult.includes(".")) {
      //결과 값에 .이 있으면 false
      setDotCount(false);
    } else {
      //결과 값에 .이 없으면 true
      setDotCount(true);
    }

    if (resultDisplay[0] !== "" && resultDisplay[0] !== "E") {
      //백스페이스 사용시 한글자만 지워질 수 있도록 문자로 변환
      calcOperator.current = resultDisplay[0]?.toString();
      setCalcDisplay(calcOperator.current);
      setTestDisplay(calcOperator.current);
      setResultDisplay([]);
    }
    try {
      //만약 계산 전에 결과가 E일 경우
      if (resultDisplay[0] === "E") {
        setCalcDisplay("E");
        setTestDisplay("E");
        setResultDisplay([]);
        calcOperator.current = "E";
      } else {
        //만약 결과 값이 17이상일 경우 에러표시
        if (!formattedResult.includes(".") && formattedResult.length >= 17) {
          setCalcDisplay("E");
          setTestDisplay("E");
          setResultDisplay([]);
          calcOperator.current = "E";
        } else {
          setResultDisplay([testDisplay, ...resultDisplay]);
          calcOperator.current = formattedResult;
          setCalcDisplay(calcOperator.current);
          setTestDisplay(calcOperator.current);
        }
      }
    } catch (e) {
      setCalcDisplay("E");
      setTestDisplay("E");
    }
  };

  //계산을 위한 수식으로 변경하는 함수
  const replaceOperator = useCallback((oper) => {
    var replaceValue = oper;
    replaceValue = replaceValue.replace(/x/g, "*").replace(/÷/g, "/");

    //숫자(숫자)의 곱셈 생략 기호시 연산할 수 있도록 생략된 곱셈기호를 추가해준다.
    //예시: 3(3+6) 형태를 3*(3+6)로 바꿔준다
    for (let i = 0; i < 10; i++) {
      //곱셈 생략 루트를 만났을 경우
      replaceValue = replaceValue.replace(i + "\u221a", i + "*\u221a");
    }

    let iLen = replaceValue.length;
    let lastOper = replaceValue.substring(iLen, iLen - 1);

    //마지막이 연산자 일 경우 삭제하고 계산
    switch (lastOper) {
      case "/":
      case "*":
      case "-":
      case "+":
        replaceValue = replaceValue.substr(0, iLen - 1);
        break;
      //괄호일 경우 루트까지 삭제하고 계산
      case "(":
        replaceValue = replaceValue.substr(0, iLen - 2);
        break;
      default:
        break;
    }

    //루트를 만날 경우 공식으로 치환
    replaceValue = replaceValue.replace(/\u221a/g, "Math.sqrt");
    replaceValue = replaceValue.substr(0);

    return replaceValue;
  }, []);

  //eval함수 대체
  const newEval = useCallback((val) => {
    try {
      return new Function("return " + val)();
    } catch (e) {
      return "E";
    }
  }, []);

  //키보드 이벤트 세팅
  useEffect(() => {
    const calcKeypressListener = (event) => {
      const inputKey = event.key;
      const keyAscii = inputKey.charCodeAt(0);

      if (keyAscii === 61 || keyAscii === 69) {
        event.preventDefault();
        //키보드 equal(=) 또는 Enter 입력시
        document.getElementById("calcEqualButton").click();
        isResult.current = true;
        return;
      }

      if (calcOperator.current.length >= 39) return;

      if (keyAscii === 95) {
        calcAddOper("-");
        return;
      }

      if (keyAscii === 47) {
        calcAddOper("÷");
        return;
      }

      if (keyAscii === 42) {
        calcAddOper("x");
        return;
      }

      if (keyAscii >= 40 && keyAscii <= 57) {
        //콤마가 아니라면
        if (keyAscii !== 44) {
          calcAddOper(inputKey);
          isResult.current = false;
          return;
        }
      }
    };

    const calcKeyUpListener = (event) => {
      //백스페이스
      if (event.key === "Backspace") {
        event.preventDefault();
        document.getElementById("calcBackspaceButton").click();
      }
    };
    const keyPressBtn = document
      .getElementById("calcBody")
      .addEventListener("keypress", calcKeypressListener);
    const keyUpBtn = document
      .getElementById("calcBody")
      .addEventListener("keyup", calcKeyUpListener);

    return () => {
      if (keyPressBtn) {
        document
          .getElementById("calcBody")
          .removeEventListener("keypress", calcKeypressListener);
      }

      if (keyUpBtn) {
        document
          .getElementById("calcBody")
          .removeEventListener("keyup", calcKeyUpListener);
      }
    };
  }, [isResult]);

  return (
    <div css={inputWrap({ isFontSmall })}>
      <input readOnly className="input_wrap" ref={ref.inputRef} tabIndex={-1} />
      <div readOnly className="text-result" css={calcTop} tabIndex={-1}>
        {resultDisplay[2]}
      </div>
      <div readOnly className="text-result" css={calcTop} tabIndex={-1}>
        {resultDisplay[1]}
      </div>
      <div readOnly className="text-result" css={calcTop} tabIndex={-1}>
        {resultDisplay[0]}
      </div>
      <input
        readOnly
        value={testDisplay}
        className="text-calc"
        css={calcTop}
        tabIndex={-1}
      />
    </div>
  );
});

export function Calculator() {
  const calcDisplayRef = useRef();
  const inputRef = useRef(null);
  const pretestData = useSelector((state) => state.pretestAction.data);
  const nowExamData = useNowExamData();
  const { exampleIndex } = useSelector((state) => state.mainExamAction);

  const onClickFocus = () => {
    inputRef.current.focus();
  };

  const refs = {
    calcDisplayRef: calcDisplayRef,
    inputRef: inputRef,
  };

  useEffect(() => {
    calcDisplayRef.current.clearCalc();
  }, [
    nowExamData?.testerStatus?.examSubNo,
    nowExamData?.testerStatus?.savePage,
    pretestData?.pageNo,
    pretestData?.examSubNo,
    exampleIndex,
  ]);

  return (
    <div className={styles.wrap} id="calcBody">
      <div className={styles.calc_wrap}>
        <CalcDisplay ref={refs} />
        <div className={styles.btn_wrap} onClick={onClickFocus}>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.clearCalc()}
          >
            C
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            id="calcBackspaceButton"
            onClick={() => calcDisplayRef.current.removeOne()}
          >
            &#9003;
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.calcAddOper("÷")}
          >
            ÷
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.rootClick()}
          >
            √
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("7")}
          >
            7
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("8")}
          >
            8
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("9")}
          >
            9
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.calcAddOper("x")}
          >
            ×
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("4")}
          >
            4
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("5")}
          >
            5
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("6")}
          >
            6
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.calcAddOper("-")}
          >
            -
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("1")}
          >
            1
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("2")}
          >
            2
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("3")}
          >
            3
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.calcAddOper("+")}
          >
            +
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("0")}
          >
            0
          </div>
          <div
            className={styles.single}
            onClick={() => calcDisplayRef.current.calcAddOper("00")}
          >
            00
          </div>
          <div
            className={`${styles.single} ${styles.gray}`}
            onClick={() => calcDisplayRef.current.calcAddOper(".")}
          >
            .
          </div>

          <div
            id="calcEqualButton"
            className={`${styles.single} ${styles.black}`}
            onClick={() => calcDisplayRef.current.equalClick()}
          >
            =
          </div>
        </div>
      </div>
    </div>
  );
}

const inputWrap = ({ isFontSmall }) => css`
  background-color: #fefefe;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  border-radius: 3px;
  position: relative;
  text-align: right;
  .input_wrap {
    position: absolute;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    :hover {
      outline: 1px solid #999;
    }
    :focus {
      border: 1px solid #999;
    }
  }

  input {
    text-align: right;
    height: 100%;
  }

  .text-result {
    font-size: 12px;
    color: #777;
    padding-right: 3px;
  }

  .text-calc {
    color: #131313;
    font-weight: 400;
    font-size: ${isFontSmall === 1 || isFontSmall === 2 ? "12px" : "16px"};
    height: 20px;
  }
`;

const calcTop = css`
  width: 100%;
  border: 0;
  height: 20px;
  overflow: hidden;
  resize: none;
`;
