/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { InsungHeader } from "../InsungHeader";
import { MaintestColumPart1 } from "./MaintestColumPart1";
import { ExampleModal } from "../../../modal/ExampleModal";
import { useSelector, useDispatch } from "react-redux";
import { MAIN_EXAM_REQUEST } from "../../../../_reducers/mainExamAction";
import { ProgressBar } from "../../../system/ProgressBar";
import {
  ALERT_MODAL_ON,
  ERROR_MODAL_OFF,
} from "../../../../_reducers/modalAction";
import { TIME_CHANGE, TIME_OUT } from "../../../../_reducers/timeAction";
import { updateApi } from "../../../../api/update/updateApi";
import { examApi } from "../../../../api/exam/examApi";
import { useDivisionExamData } from "../../../../hooks/useDivisionExamData";
import { BasicButton } from "../../../system/button/BasicButton";
import { testCompletedModal } from "../../../../utils/testCompletedModal";
import { MaintestColumPart3 } from "./MaintestColumPart3";
import { MaintestColumPart2 } from "./MaintestColumPart2";
import { InsungLayout } from "../../../layout/InsungLayout";
import { ModalTemplate } from "../../../base/ModalTemplate";
import { getInsungPart } from "../../../../utils/getInsungPart";
import { useTranslation } from "react-i18next";
import { PendingLoading } from "../../../pending/PendingLoading";
import { InsungBottomTextGuide } from "../pretest/InsungBottomTextGuide";

//likertYN 척도,ipsativeYN 무멀가
export const MainTestInsung = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [exampleModal, setExampleModal] = useState(true); //예제 모달
  const initialState = useSelector((state) => state.mainExamAction.data);
  const examData = useDivisionExamData();
  const testerStatus = initialState?.testerStatus;
  const questionInfoData = examData?.questionInfoData;

  const { timeOut } = useSelector((state) => state.timeAction.time); //시간이 종료 됐을 때
  const { waitingModal } = useSelector((state) => state.modalAction);

  const [numSetCheck, setNumSetCheck] = useState({
    QsetNum: null, // 시작은 1
    Qnum: null, // 시작은 0
  });

  const [numCheck, setNumCheck] = useState(false);
  const [examAnswerPart1, setExamAnswerPart1] = useState([]); //인성 파트 1 초기 배열 세팅
  const [examAnswerPart2, setExamAnswerPart2] = useState([]); //인성 파트 2 초기 배열 세팅
  const [examAnswerPart3, setExamAnswerPart3] = useState([]);
  const { kickModal } = useSelector((state) => state.modalAction);
  const [isNextConfirmModal, setIsNextConfirmModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const questionList = examData?.questionInfoData?.questionList[0];
  const { pilotUseYN } = useSelector((state) => state.userAction.data);

  const value = []; //척도 번호 배열에 넣기
  [...Array(examData?.maxScale)].map((a, i) => value.push(i + 1));
  const totalPage = initialState?.exam ? testerStatus?.totalPage : 1;
  const [completed, setCompleted] = useState(0);

  //한 세트의 문항 수
  const nowPageSetNum = examData?.questionInfoData?.questionList?.length;

  const insungPart = getInsungPart(examData);

  const getPartTextGuide = () => {
    if (pilotUseYN === "Y") {
      return t(`exam.insung.textGuide.pretest`);
    } else if (insungPart === "part1") {
      return t(`exam.insung.textGuide.maintest.part1`);
    } else if (insungPart === "part2") {
      return t(`exam.insung.textGuide.maintest.part2`);
    }
  };

  //세트 문항의 인덱스를 구하는 함수
  const getSetIndex = (number, firstNum, setNum) => {
    if (number === firstNum) {
      return 0;
    } else if (number === firstNum + setNum) {
      return 1;
    } else {
      return 2;
    }
  };

  const updatePart2InsungMarkArrayAndNextPage = async (examAnswerArray) => {
    setLoading(true);
    await updateApi
      .updateInsungMark(initialState, examAnswerArray)
      .then(() => {
        nextPage();
      })
      .catch(() => {
        resetAnswer();
        setLoading(false);
      });
  };

  //답안 초기화
  const resetAnswer = (setNum) => {
    insungPar3AnswerArray(examData);
    setExamAnswerPart1(resetIndexPart1AnswerArray(setNum));
    insungPart2AnswerArray();
  };

  //답안 업데이트
  const updateInsungMarkArray = async (examAnswerArray) => {
    let setNum = getSetIndex(
      examAnswerArray[0].questionNo,
      questionInfoData.firstNo,
      questionInfoData.questionList.length
    );

    if (
      (insungPart === "part1" || insungPart === "part3") &&
      parseInt(sessionStorage.getItem("remainingSeconds")) !== 0
    ) {
      //합 3 검증 로직 추가
      let totalIpsativeSum = 0;

      for (let i = 0; i < examAnswerArray.length; i++) {
        totalIpsativeSum += examAnswerArray[i].questionMarkIpsative;
      }
      if (totalIpsativeSum !== 3) {
        resetAnswer(setNum);
        dispatch({
          type: ALERT_MODAL_ON,
          data: t("error.saveAnswer"),
        });
        return;
      }
    }
    setLoading(true);
    await updateApi
      .updateInsungMark(initialState, examAnswerArray)
      .then((response) => {
        if (
          parseInt(sessionStorage.getItem("remainingSeconds")) !== 0 &&
          response?.data?.data === false
        ) {
          resetAnswer(setNum);
          dispatch({
            type: ALERT_MODAL_ON,
            data: t("error.saveAnswer"),
          });
        }
        setLoading(false);
      })
      .catch(() => {
        resetAnswer(setNum);
        setLoading(false);
      });
  };

  //클릭 시 툴팁 초기화
  useEffect(() => {
    setNumSetCheck({
      QsetNum: null,
      Qnum: null,
    });
    setNumCheck(null);
  }, [examAnswerPart1, examAnswerPart2, examAnswerPart3]);

  useEffect(() => {
    //페이지 바뀌면 배열 값 초기화
    setExamAnswerPart1([]);
    setExamAnswerPart2([]);
    setExamAnswerPart3([]);
    //본검사 예제 시 모달 띄워주기
    if (testerStatus?.examSubStatus === 0) {
      setExampleModal(true);
    } else {
      setExampleModal(false);
      dispatch({ type: ERROR_MODAL_OFF });
    }
  }, [
    testerStatus?.examSubNo,
    testerStatus?.examSubStatus,
    testerStatus?.savePage,
    totalPage,
  ]);

  useEffect(() => {
    //페이지네이션 퍼센트
    if (initialState.testerStatus?.examSubStatus === 0) {
      setCompleted(100);
    } else {
      setCompleted(Math.ceil((100 / totalPage) * testerStatus?.savePage));
    }

    insungPar3AnswerArray(examData);
    insungPart1AnswerArray(questionInfoData?.questionList);
    insungPart2AnswerArray();
  }, [examData]);

  const resetIndexPart1AnswerArray = (updateIndex) => {
    const tmp = [];
    for (let i = 0; i < nowPageSetNum; i++) {
      tmp.push(
        Array(nowPageSetNum).fill({
          questionNo: null,
          questionMark: null,
          questionMarkIpsative: null,
        })
      );
    }
    let answerArray = [...examAnswerPart1];

    answerArray[updateIndex] = tmp;
    return answerArray;
  };

  //초기 배열 세팅 (인성 파트 1)
  const insungPart1AnswerArray = (questionList) => {
    const tmp = [];
    let firstNo = questionInfoData?.firstNo;
    for (let i = 0; i < nowPageSetNum; i++) {
      tmp.push(
        Array(nowPageSetNum).fill({
          questionNo: null,
          questionMark: null,
          questionMarkIpsative: null,
        })
      );
    }
    for (let i = 0; i < nowPageSetNum; i++) {
      for (
        let j = 0;
        j < questionInfoData?.questionList[i]?.questions?.length;
        j++
      ) {
        tmp[i][j] = {
          questionNo: firstNo,
          questionMark: questionList[i]?.questions[j]?.questionMark
            ? questionList[i]?.questions[j]?.questionMark
            : null,
          questionMarkIpsative:
            questionList[i]?.questions[j]?.questionMarkIpsative !== 1
              ? questionList[i]?.questions[j]?.questionMarkIpsative
              : null,
        };
        firstNo++;
      }
    }
    setExamAnswerPart1(tmp);
  };

  //초기 배열 세팅 (인성 파트 2)
  const insungPart2AnswerArray = () => {
    const tmp = [];
    let firstNo = questionInfoData?.firstNo;
    tmp.push(
      Array(examData?.questionInfoData?.questionList[0].length).fill({
        questionNo: null,
        questionMark: null,
      })
    );
    for (let i = 0; i < tmp[0].length; i++) {
      tmp[0][i] = { questionNo: firstNo, questionMark: null };
      firstNo++;
    }
    setExamAnswerPart2(tmp[0]);
  };

  //초기 배열 세팅 (인성 파트 3)
  const insungPar3AnswerArray = (examData) => {
    const tmp = [];
    let firstNo = questionInfoData?.firstNo;
    for (let i = 0; i < nowPageSetNum; i++) {
      tmp.push(
        Array(nowPageSetNum).fill({
          questionNo: null,
          questionMarkIpsative: null,
        })
      );
    }
    for (let i = 0; i < nowPageSetNum; i++) {
      for (
        let j = 0;
        j < questionInfoData?.questionList[i]?.questions?.length;
        j++
      ) {
        tmp[i][j] = {
          questionNo: firstNo,
          questionMarkIpsative:
            examData?.questionInfoData?.questionList[i]?.questions[j]
              ?.questionMarkIpsative === 1
              ? null
              : examData?.questionInfoData?.questionList[i]?.questions[j]
                  ?.questionMarkIpsative,
        };
        firstNo++;
      }
    }
    setExamAnswerPart3(tmp);
  };

  //(척도) 툴팁으로 띄워줘야하는 것이 있는지(체크 안한 항목이 있는지) 확인한다.
  const checkNotNum = (examAnswerPart) => {
    for (let j = 0; j < examAnswerPart?.length; j++) {
      // 세트 내 문항 체크 여부 확인
      if (
        examAnswerPart[j].questionMark === null ||
        !examAnswerPart[j].questionMark
      ) {
        // 퀘스쳔 마크가 널이라면 그부분에 툴팁을 띄운다.
        return j + 1;
      }
    }
  };

  //(척도) 툴팁으로 띄워줘야하는 것이 있는지(체크 안한 항목이 있는지) 확인한다. -> part1적용
  const getNotCheckNumInfo = (examAnswerPart, questionSetNumber) => {
    const questions = examAnswerPart[questionSetNumber];
    const checkNum = checkNotNum(questions);
    if (checkNum) {
      return {
        QsetNum: questionSetNumber + 1,
        Qnum: checkNum,
      };
    }
  };

  //(멀다 가깝다) 툴팁으로 띄워줘야하는 것이 있는지(체크 안한 항목이 있는지) 확인한다.  띄워줘야하는 것이 없다면 null을 반환한다.
  const getNotCheckSetInfo = (examAnswerPart, questionSetNumber) => {
    const questions = examAnswerPart[questionSetNumber];
    let notNullCount = 0; // 멀다 가깝다 체크 여부 카운트 확인을 위한 변수
    for (let j = 0; j < questions?.length; j++) {
      // null 개수를 체크해서 null이 아닌 개수가 2개가 맞는지 확인한다.
      if (questions[j]?.questionMarkIpsative !== null) {
        notNullCount++;
      }
    }
    if (notNullCount < 2) {
      return {
        QsetNum: questionSetNumber + 1,
        Qnum: null,
      };
    }
    //해당 세트에 문제가 없다면 모두 널을 반환한다.
    return {
      QsetNum: null,
      Qnum: null,
    };
  };

  //Part1 툴팁
  const getNotCheckSetInfoPart1 = (examAnswerPart, questionSetNumber) => {
    const checkNum = getNotCheckNumInfo(examAnswerPart, questionSetNumber);
    const checkSet = getNotCheckSetInfo(examAnswerPart, questionSetNumber);
    return checkNum ? checkNum : checkSet;
  };

  //API를 쏘기 전에 null인 해당 항목을 1로 바꾸는 작업을 후 api 쏘기
  const changeNull = async (examAnswer, setNumber) => {
    const tmpArray = JSON.parse(JSON.stringify(examAnswer));
    await tmpArray[setNumber].filter((a, i) => {
      if (a.questionMarkIpsative === null) {
        a.questionMarkIpsative = 1;
      }
    });
    // API를 쏜다.
    await updateInsungMarkArray(tmpArray[setNumber]);
  };

  const nextPageHandler = async () => {
    if (isLoading) return;
    //다음 과목이 있을 경우 확인 모달 띄우기
    if (
      testerStatus?.maxExamSubNo > testerStatus?.examSubNo &&
      testerStatus?.totalPage === testerStatus?.savePage
    ) {
      setIsNextConfirmModal(true);
      return;
    }
    //검사 종료 일 경우
    if (
      testerStatus?.maxExamNo === testerStatus?.examNo &&
      testerStatus?.totalPage === testerStatus?.savePage &&
      testerStatus?.maxExamSubNo === testerStatus?.examSubNo
    ) {
      //검사 종료 시 파트 2 일 경우 답안 전송 후 종료
      if (
        initialState?.exam.likertYN === "Y" &&
        initialState?.exam.ipsativeYN === "N"
      ) {
        await updateInsungMarkArray(examAnswerPart2);
      }

      await testCompletedModal(dispatch, waitingModal);
      return;
    }

    if (initialState?.exam.ipsativeYN === "N") {
      //인성파트 2일 경우 답안 업데이트 + 다음 페이지 이동
      await updatePart2InsungMarkArrayAndNextPage(examAnswerPart2);
    } else {
      await nextPage();
    }
  };

  const nextPage = async () => {
    setLoading(true);
    await examApi
      .examStart(
        testerStatus?.savePage + 1,
        testerStatus?.examSubStatus,
        testerStatus
      )
      .then((response) => {
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: response.data.data,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const nextExamPage = async () => {
    //인성파트 2일 경우 답안 업데이트
    if (initialState?.exam.ipsativeYN === "N") {
      await updateInsungMarkArray(examAnswerPart2);
    }
    setLoading(true);
    await examApi
      .examStart(
        testerStatus?.savePage,
        testerStatus?.examSubStatus + 1,
        testerStatus
      )
      .then((result) => {
        //다음 문항 세팅
        dispatch({
          type: MAIN_EXAM_REQUEST,
          data: result.data.data,
        });
        dispatch({
          type: TIME_CHANGE,
          data: result.data.data.example.practiceMinutes * 60,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  // 다음버튼을 눌렀을 때
  const submitAnswerCheckHandler = () => {
    //만약 파트 3 일 경우
    if (examData.likertYN === "N" && examData.ipsativeYN === "Y") {
      for (let i = 0; i < examAnswerPart3?.length; i++) {
        const check = getNotCheckSetInfo(examAnswerPart3, i);
        if (check?.QsetNum !== null) {
          setNumSetCheck(check);
          return false;
        }
      }
      nextPageHandler();
      return true;
    }
    //파트 2일 경우 멀다 가깝다가 없을 때
    if (examData.likertYN === "Y" && examData.ipsativeYN === "N") {
      const check = checkNotNum(examAnswerPart2);
      if (check) {
        setNumCheck(check);
        return false;
      } else {
        nextPageHandler();
        return true;
      }
    }
    //파트 1일 경우, 멀다 가깝다가 있을 때 (ipsativeYN 멀다 가깝다)
    //문항이 체크 되어있는지 확인 하는 for 문
    if (initialState?.exam?.ipsativeYN === "Y") {
      for (let i = 0; i < examAnswerPart1?.length; i++) {
        // 체크 안한 것이 있느냐
        const check = getNotCheckSetInfoPart1(examAnswerPart1, i);
        if (check?.QsetNum !== null || check?.Qnum !== null) {
          // 하나라도 문제가 있네
          setNumSetCheck(check);
          return false;
        }
      }
      // 모두 통과완료
      setNumSetCheck({
        QsetNum: null,
        Qnum: null,
      });

      nextPageHandler();
      return true;
    }
  };

  // 타이머 종료 시 못 푼 문제 보내기
  useEffect(() => {
    if (
      (initialState?.exam?.likertYN === "Y" ||
        initialState?.exam?.ipsativeYN === "Y") &&
      testerStatus?.examSubStatus === 1
    ) {
      if (timeOut === "end" || kickModal) {
        if (insungPart === "part1") {
          checkEmptyExamAnswerPart1();
        } else if (insungPart === "part2") {
          updateInsungMarkArray(examAnswerPart2);
        } else if (insungPart === "part3") {
          checkEmptyExamAnswerPart3();
        }
        dispatch({ type: TIME_OUT, data: null });
      }
    }
  }, [timeOut, kickModal]);

  // 인성 파트 1 타이머가 0일때, 빈 답안이 있는지 확인
  const checkEmptyExamAnswerPart1 = () => {
    for (let i = 0; i < examAnswerPart1.length; i++) {
      // 체크 안한 것이 있느냐
      const check = getNotCheckSetInfoPart1(examAnswerPart1, i);
      // 모두 null인지 아닌지 체크
      if (check.QsetNum !== null) {
        const isMarkNull = examAnswerPart1[check.QsetNum - 1].reduce(
          (sum, currValue) => sum + currValue.questionMark,
          0
        );
        const isIpsativeNull = examAnswerPart1[check.QsetNum - 1].reduce(
          (sum, currValue) => sum + currValue.questionMarkIpsative,
          0
        );
        if (isMarkNull + isIpsativeNull > 0) {
          updateInsungMarkArray(examAnswerPart1[check.QsetNum - 1]);
        }
      }
    }
  };

  // 인성 파트 3 타이머가 0일때, 빈 답안이 있는지 확인
  const checkEmptyExamAnswerPart3 = async () => {
    for (let i = 0; i < examAnswerPart3?.length; i++) {
      // 체크 안한 것이 있느냐
      const noCheck = getNotCheckSetInfo(examAnswerPart3, i);
      if (noCheck.QsetNum !== null) {
        await updateInsungMarkArray(examAnswerPart3[noCheck.QsetNum - 1]);
      }
    }
  };

  //다음 버튼 컴포넌트
  const showNextButton = () => {
    if (initialState?.example) return null;
    //인성1 -> 인성2 -> 인성3
    if (
      testerStatus?.maxExamSubNo > testerStatus?.examSubNo &&
      testerStatus?.totalPage === testerStatus?.savePage
    ) {
      return (
        <BasicButton
          hei="s"
          onClick={submitAnswerCheckHandler}
          label={t(`exam.button.complete`)}
        />
      );
    }
    //인성 -> 적성
    else if (
      testerStatus?.maxExamNo > testerStatus?.examNo &&
      testerStatus?.maxExamSubNo === testerStatus?.examSubNo &&
      testerStatus?.totalPage === testerStatus?.savePage
    ) {
      return (
        <BasicButton
          hei="s"
          onClick={submitAnswerCheckHandler}
          label={t(`exam.button.nextJuksung`)}
        />
      );
    }
    //마지막 검사
    else if (
      testerStatus?.maxExamNo === testerStatus?.examNo &&
      testerStatus?.totalPage === testerStatus?.savePage &&
      testerStatus?.maxExamSubNo === testerStatus?.examSubNo
    ) {
      return (
        <BasicButton
          hei="s"
          onClick={submitAnswerCheckHandler}
          label={t(`exam.button.nextExam`)}
        />
      );
    }
    //다음 페이지
    else {
      return (
        <BasicButton
          wid="s"
          hei="s"
          onClick={submitAnswerCheckHandler}
          label={t(`exam.button.nextLabel`)}
        />
      );
    }
  };

  return (
    <>
      <PendingLoading loading={isLoading} />
      {isNextConfirmModal && (
        <ModalTemplate closeBtn={true} setModal={setIsNextConfirmModal}>
          <div css={confirmModalMessage}>
            {t(`exam.insung.modal.completeInsung`)}
          </div>
          <div css={buttonGroup}>
            <BasicButton
              wid="s"
              hei="s"
              onClick={() => {
                setIsNextConfirmModal(false);
                nextExamPage();
              }}
              label={t(`exam.button.confirm`)}
            />
          </div>
        </ModalTemplate>
      )}
      <div css={totalLayout}>
        <InsungLayout>
          <ProgressBar
            completed={completed}
            nowPage={initialState?.exam ? testerStatus?.savePage : 1}
            totalPage={totalPage}
          />
          <div css={columWrapper}>
            <InsungHeader examData={examData} />
            {questionInfoData?.questionList?.map((a, index) => {
              if (insungPart === "part1") {
                return (
                  <MaintestColumPart1
                    numSetCheck={numSetCheck}
                    questionList={a}
                    examAnswerPart1={examAnswerPart1}
                    setExamAnswerPart1={setExamAnswerPart1}
                    getNotCheckSetInfoPart1={getNotCheckSetInfoPart1}
                    changeNull={changeNull}
                    index={index}
                    key={index}
                  />
                );
              } else if (insungPart === "part2") {
                return (
                  <MaintestColumPart2
                    questionList={questionList}
                    noCheckNum={numCheck}
                    examAnswerPart2={examAnswerPart2}
                    setExamAnswerPart2={setExamAnswerPart2}
                    key={index}
                  />
                );
              } else if (insungPart === "part3") {
                return (
                  <MaintestColumPart3
                    questionList={a}
                    examAnswerPart3={examAnswerPart3}
                    setExamAnswerPart3={setExamAnswerPart3}
                    numSetCheck={numSetCheck}
                    getNotCheckSetInfo={getNotCheckSetInfo}
                    changeNull={changeNull}
                    index={index}
                    key={index}
                  />
                );
              }
            })}
          </div>
          {initialState.example && (
            <InsungBottomTextGuide text={getPartTextGuide()} />
          )}
        </InsungLayout>
        <InsungLayout>
          <div css={buttonWrapper}>
            <div className="prev-btn-wrap"></div>
            <div className="example-btn-wrap">
              {initialState?.example ? (
                <BasicButton
                  hei="s"
                  onClick={() => setExampleModal(true)}
                  label={questionInfoData?.buttonNamePopUp}
                />
              ) : null}
            </div>
            <div>{showNextButton()}</div>
          </div>
        </InsungLayout>
      </div>
      {exampleModal && (
        <ExampleModal
          exImage={questionInfoData?.popUpImg}
          exTitle={questionInfoData?.buttonNamePopUp}
          setModal={setExampleModal}
        />
      )}
    </>
  );
};

const totalLayout = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const columWrapper = css`
  height: 100%;
`;
const buttonWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  bottom: 10px;
`;

const confirmModalMessage = css`
  margin: 40px 20px 10px;
  white-space: pre;
  text-align: center;
  font-size: 1rem;
`;

const buttonGroup = css`
  padding: 20px;
  left: 30%;
`;
