/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import i18n from "../../../locales/i18n";
import { useSelector } from "react-redux";

export const InsungMainGuide = () => {
  const { pilotUseYN } = useSelector((state) => state.userAction.data);

  return (
    <>
      {i18n.language === "KOR" ? (
        <>
          <div css={noticeTitle}>Test 안내</div>
          <div
            css={noticeSubTitle}
            style={{ width: pilotUseYN === "N" ? "100%" : "" }}
          >
            <strong>
              이 검사는 개인의 생각, 태도와 행동들을 표현하는 문항들로 구성되어
              있습니다.
            </strong>
          </div>
        </>
      ) : (
        <>
          <div css={noticeTitle}>Test Guidelines</div>
          <div css={noticeSubTitle}>
            This test consists of questions that ask about your thoughts,
            attitudes and behaviors.
          </div>
        </>
      )}

      {i18n.language === "KOR" ? (
        <>
          <div css={noticeBox}>
            <div>
              • 이 검사에는 옳고 그른 정답이 없으므로 평소 자신의 모습을
              떠올리며 솔직하게 응답하시기 바랍니다.
            </div>
            <div>
              • 이 검사의 문항들 중 동일하거나 유사한 문항들이 있더라도 모든
              문항에 빠짐없이 응답하셔야 합니다.
            </div>
            {pilotUseYN === "N" && (
              <>
                <div>
                  • 이 검사는 시간 제한이 있으므로 각 문항에 대해 지나치게 깊게
                  고민하지 마시고 머리 속에 떠오르는 대로 바로바로 응답해주시기
                  바랍니다.
                </div>
                <div>
                  • 이 검사는 PART 1과 PART 2로 구분되어 있으며, PART별 제한
                  시간내에 모두 응답을 완료하셔야 합니다.
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div css={noticeBox}>
            <div>
              • There is no correct or incorrect answer to each question. Please
              answer honestly based on your everyday thoughts and behaviors.
            </div>
            <div>
              • There may be some questions that look identical or similar, but
              ensure to answer all questions with no omission.
            </div>
            <div>
              • This is a time-limited test. Do not spend too much time on one
              question, but answer with what instantly comes to your mind.
            </div>
            <div>
              • The test has two Parts: Part 1 and Part2. Each Part must be
              completed within the set time limit.
            </div>
          </div>
        </>
      )}
    </>
  );
};

const noticeTitle = css`
  max-width: 1000px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0 20px 0;
`;

const noticeSubTitle = css`
  max-width: 1000px;
  text-align: left;
  font-size: 20px;
  margin: 10px 0 20px 0;
  font-weight: 900;
`;

const noticeBox = css`
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 30px;
`;
