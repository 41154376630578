/** @jsxImportSource @emotion/react */
import { css, jsx } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToolTip } from "../../../system/ToolTip";
import { JuksungQuestionText } from "../JuksungQuestionText";
import { DrawingMemoCalc } from "../../../tool/DrawingMemoCalc";
import { PretestNextPrevButton } from "../../../system/button/PretestNextPrevButton";
import { PretestJuksungQuestionAnswer } from "./PretestJuksungQuestionAnswer";
import { SHOW_CHAT } from "../../../../_reducers/showUtilsAction";
import { useTooltip } from "../../../../hooks/useTooltip";
import { PagiNationButton } from "../../../system/button/PagiNationButton";
import { ProgressBar } from "../../../system/ProgressBar";
import { ReplyAnswerForm } from "../../../system/form/ReplyAnswerForm";
import { useTranslation } from "react-i18next";

export const PreTestJuksung = ({ setRender, setModal, modal }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const examData = useSelector((state) => state.pretestAction.data);
  const isReply =
    examData?.questionInfoData?.questionList[0]?.questionEx.length === 0;
  const { recordUseYN } = useSelector((state) => state.userAction.data);

  const { tooltipArray } = useTooltip();
  const [toolTipIndex, setToolTipIndex] = useState(0);
  const toolTip = tooltipArray[toolTipIndex];
  const [completed, setCompleted] = useState(0);
  const { pageNo, pageNoMax, examSubNo, prevUseYN } = useSelector(
    (state) => state.pretestAction.data
  );

  const [markInfosArray, setMarkInfosArray] = useState(null);

  const initialMaxArray = (num) => {
    const maxPageArray = new Array(num).fill(false);
    return maxPageArray;
  };
  useEffect(() => {
    let initialMarkInfoArray = initialMaxArray(examData?.pageNoMax);
    setMarkInfosArray(initialMarkInfoArray);
  }, [examData.examSubNo]);

  const getPercent = () => {
    setCompleted(Math.ceil((100 / pageNoMax) * pageNo));
  };

  useEffect(() => {
    setCompleted(getPercent);
  }, [examSubNo, pageNo]);

  useEffect(() => {
    //툴팀이 켜져 있을 때 채팅일 경우 채팅창 열어주기
    if (toolTipIndex !== -1) {
      if (tooltipArray[toolTipIndex] === "chat") {
        dispatch({ type: SHOW_CHAT, data: true });
      }
    }
  }, [tooltipArray, toolTipIndex]);

  return (
    <>
      {toolTipIndex < tooltipArray.length && (
        <div css={modalBackground({ toolTipIndex })} />
      )}
      {toolTip === "navbar" &&
        (recordUseYN === "N" ? (
          <ToolTip
            top="20px"
            left="30%"
            location="top"
            setIndex={setToolTipIndex}
            start={true}
          >
            {t(`tooltip.navbar_no_cam`)}
          </ToolTip>
        ) : (
          <ToolTip
            top="20px"
            left="30%"
            location="top"
            setIndex={setToolTipIndex}
            start={true}
          >
            {t(`tooltip.navbar`)}
          </ToolTip>
        ))}
      <div css={totalWrapper}>
        <div css={contentsContainer}>
          <div css={contentsTotalWrapper}>
            <div css={contentsWrapper}>
              <div css={progressWrap({ toolTip })}>
                {prevUseYN === "Y" ? (
                  <PagiNationButton markInfosArray={markInfosArray} />
                ) : (
                  <ProgressBar
                    completed={completed}
                    nowPage={pageNo}
                    totalPage={pageNoMax}
                  />
                )}

                {toolTip === "page" && (
                  <ToolTip
                    top="100px"
                    right="40%"
                    location="top"
                    setIndex={setToolTipIndex}
                  >
                    {prevUseYN === "Y"
                      ? t(`tooltip.juksung.paginationPrevY`)
                      : t(`tooltip.juksung.paginationPrevN`)}
                  </ToolTip>
                )}
              </div>

              <div css={questionWrapper({ toolTip })}>
                <JuksungQuestionText />
                {isReply ? (
                  <div css={shortFormBox}>
                    <ReplyAnswerForm
                      setMarkInfosArray={setMarkInfosArray}
                      markInfosArray={markInfosArray}
                    />
                  </div>
                ) : (
                  <PretestJuksungQuestionAnswer setRender={setRender} />
                )}
              </div>
              <div css={buttonTooltip({ toolTip })}>
                <PretestNextPrevButton
                  setModal={setModal}
                  modal={modal}
                  toolTip={toolTip}
                  setToolTipIndex={setToolTipIndex}
                />
                {toolTip === "button" && (
                  <ToolTip
                    bottom="100px"
                    location="bottom"
                    setIndex={setToolTipIndex}
                  >
                    {t(`tooltip.juksung.buttonExample`)}
                  </ToolTip>
                )}
                {toolTip === "nextButton" && (
                  <ToolTip
                    bottom="100px"
                    right="-250px"
                    location="bottom"
                    setIndex={setToolTipIndex}
                    end={
                      examData.memoUseYN === "N" &&
                      examData.calculatorUseYN === "N"
                        ? true
                        : false
                    }
                  >
                    {t(`tooltip.juksung.buttonPrevNext`)}
                  </ToolTip>
                )}
              </div>
              {toolTip === "question" && (
                <ToolTip
                  top="40px"
                  right="-320px"
                  location="left"
                  setIndex={setToolTipIndex}
                >
                  {t(`tooltip.juksung.question`)}
                </ToolTip>
              )}
            </div>
          </div>
          {/* 메모장 계산기 */}
          <div css={sideWrapper({ toolTip })}>
            {toolTip === "tool" && (
              <ToolTip
                top="80px"
                right="330px"
                location="right"
                setIndex={setToolTipIndex}
              >
                {t(`tooltip.juksung.utils.text`)}
                <br />
                {prevUseYN === "Y" && (
                  <div>{t(`tooltip.juksung.utils.prevY`)}</div>
                )}
              </ToolTip>
            )}
            <DrawingMemoCalc />
          </div>
          <div css={chatTooltip}>
            {toolTip === "chat" && (
              <ToolTip
                top="120px"
                right="380px"
                location="right"
                setIndex={setToolTipIndex}
                end={true}
              >
                {t(`tooltip.chat`)}
              </ToolTip>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const chatTooltip = ({ toolTip }) => css`
  z-index: ${toolTip === "chat" && 8};
  background-color: white;
`;

const buttonTooltip = ({ toolTip }) => css`
  width: 100%;
  z-index: ${(toolTip === "button" || toolTip === "nextButton") && 999};
  display: ${(toolTip === "button" || toolTip === "nextButton") && "flex"};
  justify-content: ${toolTip === "button" && "center"};
`;

const modalBackground = ({ toolTipIndex }) => css`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  height: ${toolTipIndex !== -1 && "100vh"};
`;

const totalWrapper = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

const shortFormBox = css`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const contentsTotalWrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
`;

const questionWrapper = ({ toolTip }) => css`
  overflow-y: auto;
  overflow-x: hidden;
  height: 90%;
  z-index: ${toolTip === "question" && 999};
  background-color: white;
  width: 70vw;
  min-width: 850px;
  max-width: 1060px;
`;

const contentsWrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: show;
  position: relative;
`;

const sideWrapper = ({ toolTip }) => css`
  flex: 1;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  z-index: ${toolTip === "tool" && 999};
  position: relative;
`;

const contentsContainer = css`
  display: flex;
  height: 100%;
`;

const progressWrap = ({ toolTip }) => css`
  width: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: ${toolTip === "page" && 999};
  background-color: white;
`;
