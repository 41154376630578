/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export const ProgressBar = ({ completed, nowPage, totalPage }) => {
  return (
    <div css={progressWrap}>
      <div css={progressWrapper({ completed })}>
        <div className="progress-bar" />
      </div>
      <div className="label">{`${nowPage} / ${totalPage}`}</div>
    </div>
  );
};

const progressWrap = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .label {
    text-align: center;
    margin: 20px 0 20px 4px;
    width: 6%;
    border-radius: 10px;
    color: #000;
  }
`;
const progressWrapper = ({ completed }) => css`
  width: 94%;
  height: 13px;
  text-align: right;
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #d2d3d4;
  .progress-bar {
    height: 100%;
    background-color: #3b3b3b;
    border-radius: 20px;
    transition: 0.3s linear;
    width: ${completed}%;
  }
`;
